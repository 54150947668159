<template>
  <div>
    <Navbar page="Gestão de Cookies" video="https://youtu.be/9ocHADlbzI8" />
    <div class="mx-4 my-4 md:mx-8 md:my-8">
      <div class="grid grid-cols-12 gap-6 mb-3">
        <div class="col-span-12 md:col-span-3 2xl:col-span-2">
          <button
            @click="$modal.show('novaCookies')"
            type="button"
            class="transition duration-200 bg-yellow-300 hover:bg-yellow-400 focus:bg-yellow-700 focus:shadow-sm focus:ring-4 focus:ring-yellow-500 focus:ring-opacity-50 text-white py-2 rounded-lg text-sm shadow-sm hover:shadow-md font-semibold text-center w-full"
            :style="{ 'background-color': $store.state.colorPrincipal, 'color': invertColor($store.state.colorPrincipal, true) }"
          >
            <span class="inline-block">Criar novo cookie banner</span>
          </button>
        </div>

        <div class="col-span-12 md:col-span-2 mb-1 mt-2">
          <label for="naoContemAvaliacao" class="block text-sm font-medium mt-2">
            <input type="checkbox" v-model="arquivados" class="rounded-sm" id="naoContemAvaliacao" @change="start">
            <span class="ml-2">
                Visualizar desativadas
            </span>
          </label>
        </div>
      </div>

      <div class="flex flex-col">
        <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
              <table class="min-w-full divide-y divide-gray-200">
                <thead class="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      class="px-2 py-2 text-left text-xs font-medium text-gray-500 tracking-wider"
                    >
                      URL Site
                    </th>
                    <th
                      scope="col"
                      class="px-2 py-2 text-left text-xs font-medium text-gray-500 tracking-wider"
                    >
                      Status
                    </th>
                    <th
                      scope="col"
                      class="px-2 py-2 text-left text-xs font-medium text-gray-500 tracking-wider"
                    >
                      Acessos
                    </th>
                    <th
                      scope="col"
                      class="px-2 py-2 text-left text-xs font-medium text-gray-500 tracking-wider"
                    >
                      Obrigatórios
                    </th>
                    <th
                      scope="col"
                      class="px-2 py-2 text-left text-xs font-medium text-gray-500 tracking-wider"
                    >
                      Estatísticos
                    </th>
                    <th
                      scope="col"
                      class="px-2 py-2 text-left text-xs font-medium text-gray-500 tracking-wider"
                    >
                      Preferências
                    </th>
                    <th
                      scope="col"
                      class="px-2 py-2 text-left text-xs font-medium text-gray-500 tracking-wider"
                    >
                      Marketing
                    </th>
                    <th
                      scope="col"
                      class="px-2 py-2 text-left text-xs font-medium text-gray-500 tracking-wider"
                    >
                      Outros
                    </th>
                    <th
                      scope="col"
                      class="px-2 py-2 text-left text-xs font-medium text-gray-500 tracking-wider"
                    >
                      Opções
                    </th>
                  </tr>
                </thead>
                <tbody class="bg-white divide-y divide-gray-200">
                  <tr v-for="item in list" :key="item._id">
                    <td class="px-2 py-2 whitespace-nowrap">
                      <div class="flex items-center">
                        <div class="text-sm text-gray-500">
                          {{ item.url ? item.url : "" }}
                        </div>
                      </div>
                    </td>

                    <td class="px-2 py-2 whitespace-nowrap">
                      <div class="flex items-center">
                        <div class="text-sm text-gray-500">
                          {{ item.status }}
                        </div>
                      </div>
                    </td>

                    <td class="px-2 py-2 whitespace-nowrap">
                      <div class="flex items-center">
                        <div class="text-sm text-gray-500">
                          {{ item.acessos ? item.acessos : 0 }}
                        </div>
                      </div>
                    </td>

                    <td class="px-2 py-2 whitespace-nowrap">
                      <div class="flex items-center">
                        <div class="text-sm text-gray-500">
                          {{ item.totalObrigatorios ? item.totalObrigatorios : 0 }}
                        </div>
                      </div>
                    </td>

                    <td class="px-2 py-2 whitespace-nowrap">
                      <div class="flex items-center">
                        <div v-if="item.data" class="text-sm text-gray-500">
                          {{ item.totalEstatisticos ? item.totalEstatisticos : 0 }}
                        </div>
                      </div>
                    </td>

                    <td class="px-2 py-2 whitespace-nowrap">
                      <div class="flex items-center">
                        <div v-if="item.data" class="text-sm text-gray-500">
                          {{ item.totalPreferencias ? item.totalPreferencias : 0 }}
                        </div>
                      </div>
                    </td>

                    <td class="px-2 py-2 whitespace-nowrap">
                      <div class="flex items-center">
                        <div v-if="item.data" class="text-sm text-gray-500">
                          {{ item.totalMarketing ? item.totalMarketing : 0 }}
                        </div>
                      </div>
                    </td>

                    <td class="px-2 py-2 whitespace-nowrap">
                      <div class="flex items-center">
                        <div v-if="item.data" class="text-sm text-gray-500">
                          {{ item.totalOutros && item.totalOutros.length ? item.totalOutros.length : 0 }}
                        </div>
                      </div>
                    </td>
                    
                    <td class="px-2 py-2 text-sm font-medium">
                      <button
                        @click="getCookie(item._id)"
                        type="button"
                        class="text-white hover:bg-green-700 bg-green-600 rounded py-1 px-4 mx-1 my-1"
                      >
                        Plugin e API
                      </button>
                     
                      <router-link
                        :to="`/cookies/configurar/${item._id}`"
                        role="button"
                        type="button"
                        class="text-white hover:bg-gray-700 bg-gray-600 rounded py-1 px-4 mx-1 my-1"
                      >
                        Configurar
                      </router-link>
                      <button
                        v-if="item.ativo"
                        type="button"
                        @click="remove(item._id)"
                        class="text-white hover:bg-red-500 bg-red-700 rounded py-1 px-4 mx-1 my-1"
                      >
                        Arquivar
                      </button>
                      <button
                        type="button"
                        @click="restaurar(item._id)"
                        v-if="!item.ativo"
                        class="text-white hover:bg-yellow-500 bg-yellow-700 rounded py-1 px-4 mx-1 my-1"
                      >
                        Restaurar
                      </button>
                      <button
                        type="button"
                        @click="excluirPermanente(item._id)"
                        v-if="!item.ativo"
                        class="text-white hover:bg-red-900 bg-red-800 rounded py-1 px-4 mx-1 my-1"
                      >
                        Excluir permanentemente
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <pagination
              v-model="page"
              :per-page="perPage"
              :records="total"
              @paginate="setPage"
              class="pagination"
            />
          </div>
        </div>
      </div>

      <modal name="novaCookies" :adaptive="true" :height="'auto'">
        <div class="px-3 py-3">
          <h2 class="text-2xl font-bold mb-3">Novo Cookie Banner</h2>
          <div class="grid grid-cols-12 gap-4">

            <div class="col-span-12">
              <label for="url" class="block text-sm font-medium">URL Site</label>
              <input id="url" v-model="form.url" type="text" class="mt-2 focus:ring-yellow-400 focus:border-yellow-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-lg"/>
            </div>
            
          </div>

          <div class="grid grid-cols-2 mt-5">
            <div class="text-left">
              <button @click="$modal.hide('novaCookies')" type="button" class="inline-flex justify-center py-2 px-6 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-gray-600 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500">
                  Voltar
              </button>
            </div>
            <div class="text-right">
              <button @click="save" type="button" class="inline-flex justify-center py-2 px-6 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-yellow-300 hover:bg-yellow-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:bg-yellow-400" :style="{ 'background-color': $store.state.colorPrincipal, 'color': invertColor($store.state.colorPrincipal, true) }">
                    Salvar
                </button>
            </div>
          </div>
        </div>
      </modal>

      <modal name="pluginSite" :adaptive="true" :height="'auto'">
        <div class="px-5 py-8">
          <h2 class="text-2xl font-bold mb-3">
            Plugin para o site 
          </h2>
          <label class="block font-semibold text-base text-gray-900">
            Tutorial: 
            <a target= "_blank" rel="noreferrer" href="https://youtu.be/9ocHADlbzI8">
              <svg xmlns="http://www.w3.org/2000/svg" class="inline h-7 w-7" fill="#060505" viewBox="0 0 256 256"><rect width="256" height="256" fill="none"></rect><rect x="32" y="48" width="192" height="144" rx="16" transform="translate(256 240) rotate(180)" fill="none" stroke="#060505" stroke-linecap="round" stroke-linejoin="round" stroke-width="16"></rect><line x1="160" y1="224" x2="96" y2="224" fill="none" stroke="#060505" stroke-linecap="round" stroke-linejoin="round" stroke-width="16"></line><polygon points="160 120 112 88 112 152 160 120" fill="none" stroke="#060505" stroke-linecap="round" stroke-linejoin="round" stroke-width="16"></polygon></svg>
            </a>
          </label>
          <div class="grid grid-cols-12 gap-4 mt-2">

            <div class="col-span-12">
              <label for="codigo" class="block text-sm font-medium">Copie o código abaixo e cole <b class="text-blue-800">na <b>head</b> do seu site</b> para gerar o banner de cookies</label>
              <textarea disabled v-model="codigo" type="text" name="codigo" id="codigo" rows="5" class="mt-2 focus:ring-yellow-400 focus:border-yellow-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-lg"></textarea>
            </div>
            
          </div>

          <div class="grid grid-cols-2 mt-5">
            <div class="text-left">
                <button @click="$modal.hide('pluginSite')" type="button" class="inline-flex justify-center py-2 px-6 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-gray-600 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500">
                    Voltar
                </button>
            </div>
          </div>
        </div>
      </modal>
      <modal name="AvisoCookies" :adaptive="true" :height="'auto'" :clickToClose="false">
        <div class="bg-gray-200 px-5 py-5">
            <h2 class="text-2xl mb-2 font-bold"> DESCULPE O TRANSTORNO !!!</h2>
            <p class="text-base font-medium text-gray-900 mb-3">Este módulo está disponível apenas para assinates da plataforma. </p>
            <button @click="$modal.hide('AvisoCookies');$router.push({ path: `/` });" class="text-white hover:bg-gray-500 bg-gray-700 rounded py-1 px-4">
                OK
            </button>
        </div>
      </modal>
    </div>

  </div>
</template>

<script>

export default {
  data() {
    return {
      route: "cookies",
      list: [],
      selecionados: [],
      form: {
        url: "",
      },
      codigo: "",
      idCookie: "",
      page: 1,
      perPage: 10,
      total: 0,
      arquivados: false,
    };
  },
  methods: {
    async start() {
      const listReq = await this.$http.post(`/v1/${this.route}/list`, {
        busca: this.busca,
        limit: this.perPage,
        arquivados: this.arquivados,
      });
      this.list = listReq.data.data;
      this.total = listReq.data.total;
    },

    async getCookie(id){
      this.idCookie = id;
      const reqCookie = await this.$http.get(`/v1/${this.route}/${id}`);
      if(reqCookie.data && reqCookie.data.codigo){
        this.codigo = reqCookie.data.codigo;
        this.$modal.show('pluginSite');
      }
    },

    async excluirSelecionadas(){
      this.$confirm({
        title: 'Arquivar selecionados',
        message: `Deseja arquivar itens selecionados ?`,
        button: {
            no: 'Não',
            yes: 'Sim',
        },
        callback: async confirm => {
          if(!confirm) return;
          await this.$http.post(`/v1/${this.route}/selecionados/deletar`,{ selecionados: this.selecionados });
          this.selecionados = [];
          this.$vToastify.success("Removido com sucesso!");
          this.start();
        }
      })
    },

    async remove(id) {
      this.$confirm({
        title: 'Arquivar',
        message: `Tem certeza?`,
        button: {
            no: 'Não',
            yes: 'Sim',
        },
        callback: async confirm => {
          if(!confirm) return;
          await this.$http.delete(`/v1/${this.route}/${id}`);
          this.$vToastify.success("Removido com sucesso!");
          this.start();
        }
      })
    },
    async excluirPermanente(id) {
      this.$confirm({
        title: 'Excluir',
        message: `Tem certeza? Isso não poderá ser revertido, vai se excluído para sempre da base de dados.`,
        button: {
            no: 'Não',
            yes: 'Sim',
        },
        callback: async confirm => {
          if(!confirm) return;
          await this.$http.delete(`/v1/${this.route}/${id}/perma`);
          this.$vToastify.success("Excluido com sucesso!");
          this.start();
        }
      })
    },
    async restaurar(id) {
      this.$confirm({
        title: 'Restaurar',
        message: `Tem certeza?`,
        button: {
            no: 'Não',
            yes: 'Sim',
        },
        callback: async confirm => {
          if(!confirm) return;
          await this.$http.patch(`/v1/${this.route}/${id}`);
          this.$vToastify.success("Restaurado com sucesso!");
          this.start();
        }
      })
    },

    async save() {
      const req = await this.$http.post(`/v1/${this.route}`, this.form);
      if (req.data.success) {
          this.$vToastify.success("Gerado com sucesso!");
          this.$modal.hide('novaCookies')
          this.start();
      } else {
          this.$vToastify.error(req.data.err);
      }
    },

    async selecionarTodos(){
      const listReq = await this.$http.post(`/v1/${this.route}/list`, { all: true, busca: this.busca });
      this.selecionados = listReq.data.data;
    },

    selecionadosControl(item, e){
      if (this.selecionados.map(el => el._id).indexOf(item._id) > -1 && !e.target.checked) {
        this.selecionados.splice(this.selecionados.map(el => el._id).indexOf(item._id), 1);
      } else {
        this.selecionados.push(item);
      }
    },

    async setPage(page) {
      this.page = page;
      const skip = (page - 1) * this.perPage;
      const limit = this.perPage;

      const listReq = await this.$http.post(`/v1/${this.route}/list`, { limit, skip });
      this.list = listReq.data.data;
      this.total = listReq.data.total;
    },
  },
  mounted() {
    
    if(this.$store.state.user.freeTrial) {
      this.$modal.show('AvisoCookies');
    }
  },
  async beforeMount() {
    this.start();
  },
};
</script>
